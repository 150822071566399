import {  qspost } from '@/config/axios';

//获取列表数据
export const getList = data => qspost("request?", data ,"passageway");

//冻结*解冻
export const freezeThaw = data => qspost("request?", data,"lockPassageway");

//编辑权重
export const editWeight = data => qspost("request?",data, "updateWeight");

//添加通道
export const add = data => qspost("request?",data, "addPassageway");

//编辑通道
export const edit = data => qspost("request?",data, "updatePassageway");

//删除通道
export const del = data => qspost("request?",data, "delPassageway");

//获取充值类型
export const getPayType = data => qspost("request?",data, "getPayType");

//获取通道类型
export const getPassType = data => qspost("request?",data, "getPassType");