<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showAdd">新增通道</el-button>
<!--        <el-button type="primary" size="medium" @click="showShopList">商户管理</el-button>-->
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="通道ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="shop_title" label="所属商户" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="通道名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pass_type_2" label="通道类型" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="pass_code" label="通道说明" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div >{{showChannel(scope.row)}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="pay_type" label="支付类型" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="launch_count" label="充值次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="ok_count" label="充值成功次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="disable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.disable == 1 ? '#33C933' : '#F33333' }">{{scope.row.disable == 1 ? '启用' : '禁用'}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="freezeThaw(scope.row,scope.row.disable == 1 ? '禁用' : '启用')">{{scope.row.disable == 1 ? '禁用' : '启用' }}</span>
          <span class="operation" @click="editor(scope.row)">权重设置</span>
          <span class="operation" @click="editor2(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

<!--    <el-dialog title="商户管理" v-model="dialogShop" width="700px" top="10vh" :close-on-click-modal="false" @close="dialogShop=false">-->
<!--      <el-table :data="shopList" :header-cell-style="{background:'#F7F8FA'}">-->
<!--        <el-table-column prop="Id" label="商户ID" align="center" :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column prop="Title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column prop="LaunchCount" label="充值次数" align="center" :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column prop="OkCount" label="充值成功次数" align="center" :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column prop="TotalPrice" label="充值总金额" align="center" :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column prop="Disable" label="状态" align="center" :show-overflow-tooltip="true">-->
<!--          <template #default="scope">-->
<!--            <div :style="{color: scope.row.Disable == 1 ? '#33C933' : '#F33333' }">{{scope.row.Disable == 1 ? '启用' : '禁用'}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column label="操作" width="250px" align="center">-->
<!--          <template #default="scope">-->
<!--            <span class="operation" @click="editorShop(scope.row)">编辑</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--      <template #footer style="text-align: center;padding-top:10px;">-->
<!--        <span class="dialog-footer">-->
<!--          <el-button type="primary" @click="dialogShop = false">关闭</el-button>-->
<!--        </span>-->
<!--    </template>-->

<!--    </el-dialog>-->

    <!-- 修改权重 -->
    <el-dialog :title="title" v-model="dialog" width="492px" top="30vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">通道权重：</div>
        <el-input v-model="weight" placeholder="通道权重" size="small" type="number"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="editWeight">确认修改</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 新增通道 -->
    <el-dialog :title="title2" v-model="dialogAdd" width="750px" top="30vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">通道名称：</div>
        <el-input v-model="arr.title" placeholder="通道名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">商户：</div>
        <el-select v-model="arr.shop_id" placeholder="未选择" @change="changeShopId" size="medium" clearable>
          <el-option v-for="item in shopList" :key="item.Id" :label="item.Title" :value="item.Id"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">支付类型：</div>
        <el-select v-model="arr.pay_type" filterable placeholder="未选择" size="medium" clearable>
          <el-option v-for="item in payArr" :key="item.id" :label="item.type" :value="item.id"></el-option>
        </el-select>
      </div>
      <!-- <div class="form_box">
        <div class="form_title">通道类型：</div>
        <el-select v-model="arr.pass_type" filterable placeholder="未选择" size="medium" clearable>
          <el-option v-for="item in passArr" :key="item.id" :label="item.type" :value="item.id"></el-option>
        </el-select>
      </div> -->
      <div class="form_box">
        <div class="form_title">通道类型：</div>
        <el-checkbox-group v-model="arr.pass_type" size="small" style="height: auto">
          <el-checkbox-button v-for="city in passArr" :label="city.value" :key="city.label">{{city.label}}</el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="form_box">
        <div class="form_title">通道说明：</div>
        <el-select v-model="arr.pass_code" filterable placeholder="未选择" size="medium" clearable>
          <el-option v-for="item in typeList" :key="item.value" :label="item.label + '(' + item.value + ')'" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="form_box">
        <div class="form_title">通道备注信息：</div>
        <el-input v-model="arr.pass_remarks" placeholder="通道备注信息" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title2 == '新增通道'">确认新增</el-button>
          <el-button type="primary" @click="edit" v-else>确认编辑</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  freezeThaw,
  editWeight,
  add,
  edit,
  del,
  getPayType,
  getPassType,
} from "@/api/finance/passageway";
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "financeRecharge",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
      },
      dialogShop:false,
      tableData: [], //数据
      dialog: false, //权重修改弹出层
      dialogAdd: false, //
      weight: 0, //权重
      title: "", // 弹出层标题
      arr: {
        shop_id: 1, //标题
        title: "", //标题
        pass_remarks: "", //通道备注
        pass_code: "", // 支付类型
        pay_type: "", // 充值类型
        pass_type: [], // 通道类型
      },
      title2: "新增通道",
      typeList:[],
      typeArr: [
        // { value: "103", label: "QQ红包【区间金额：30-200】" },
        // { value: "104", label: "微信聚合码截图扫码" },
        // { value: "333", label: "腾讯综合游戏" },
        // { value: "400", label: "支付宝小荷包" },
        // { value: "401", label: "网关" },
        // { value: "501", label: "银联" },
        // { value: "502", label: "支转卡" },
        // { value: "503", label: "微转卡" },
        // { value: "504", label: "卡转卡" },
        // { value: "567", label: "支付宝天猫旗舰店" },
        // { value: "601", label: "闲鱼SDK" },
        // { value: "602", label: "闲鱼H5" },
        // { value: "677", label: "支付宝原生" },
        // { value: "688", label: "支付宝话费超级慢充" },
        // { value: "701", label: "PDD支付宝" },
        // { value: "702", label: "PDD微信" },
        // { value: "703", label: "PDD支付宝SDK" },
        // { value: "730", label: "微信超级原生" },
        // { value: "801", label: "微信话费" },
        // { value: "802", label: "微信慢充" },
        // { value: "803", label: "微信扫码话费" },
        // { value: "805", label: "微信京东慢充" },
        // { value: "806", label: "微信油卡" },
        // { value: "807", label: "微信小额原生" },
        // { value: "808", label: "微信原生" },
        // { value: "811", label: "微信苏宁商城" },
        // { value: "820", label: "微信代充原生" },
        // { value: "902", label: "支付宝天然气" },
        // { value: "903", label: "支付宝IOS专属" },
        // { value: "904", label: "淘宝代付" },
        // { value: "905", label: "支付宝原生" },
        // { value: "906", label: "支付宝慢充" },
        // { value: "907", label: "支付宝话费" },
        // { value: "908", label: "支付宝转账" },
        // { value: "909", label: "支付宝原生" },
        // { value: "910", label: "代金券H5" },
        // { value: "911", label: "支付宝YY" },
        // { value: "912", label: "支付宝安卓专属" },
        // { value: "913", label: "支付宝话费SDK" },
        // { value: "916", label: "口令红包" },
        // { value: "917", label: "支付宝快充" },
        // { value: "918", label: "支付宝电网" },
        // { value: "919", label: "支付宝原生2" },
        // { value: "930", label: "支付宝原生(H5)" },
        // { value: "933", label: "微信原生" },
        // { value: "970", label: "微信花椒【夜间推荐】" },
        // { value: "993", label: "支付宝UID" },
      ],
      payArr: [], // 充值类型数组
      passArr: [
      ],
      shopList:[ // 商户列表

      ],
    };
  },
  computed: {
  },
  created() {},
  mounted() {
    this.getShopList()
    this.getList();
    this.getPayType();
    this.getPassType();
  },
  methods: {
    changeShopId(_shop_id){
      if (_shop_id == undefined){
        _shop_id = this.arr.shop_id
      }
      this.typeList =  this.typeArr.filter((item)=>{
        return  item.shop_id == _shop_id;
      })
    },
    showChannel(row){
      for(var i in this.typeArr){
        if(this.typeArr[i].value == row.pass_code && this.typeArr[i].shop_id == row.shop_id){
          return this.typeArr[i].label
        }
      }
      return "未知通道:"+row.pass_code
    },
    //获取数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
      ])
        .then((res) => {
          if (res.code == 0) {
            console.log(res);
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showAdd(){
      this.changeShopId()
      this.title2 = '新增通道'
      this.dialogAdd = true
    },
    getShopList(){
      httpClient("getShopList").post().then((res)=>{
        this.shopList = res.data.shop_list
        this.typeArr = res.data.code_list
      })
    },
    // 获取充值类型
    getPayType() {
      getPayType()
        .then((res) => {
          if (res.code == 0) {
            this.payArr = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取通道类型
    getPassType() {
      getPassType()
        .then((res) => {
          if (res.code == 0) {
            this.passArr = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 禁用启用
    freezeThaw(row, str) {
      this.row = row;
      this.$confirm("你确定要" + str + "通道：" + row.title + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          freezeThaw([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑前数据填充
    editor(row) {
      this.row = row;
      this.title = `编辑权重(ID：${row.id})`;
      this.weight = row.weight;
      this.dialog = true;
    },
    // 修改权重
    editWeight() {
      editWeight([
        { key: "id", val: this.row.id },
        { key: "weight", val: this.weight },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialog = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 弹窗关闭回调
    closeDialog() {
      this.weight = 0;
      this.arr.title = "";
      this.arr.pass_remarks = "";
      this.arr.pass_code = "";
      this.arr.pay_type = "";
      this.arr.pass_type = [];
    },
    // 新增通道
    add() {
      console.log(this.arr)
      add([
        { key: "title", val: this.arr.title },
        { key: "pass_remarks", val: this.arr.pass_remarks },
        { key: "pass_code", val: this.arr.pass_code },
        { key: "pay_type", val: this.arr.pay_type },
        { key: "shop_id", val: this.arr.shop_id },
        { key: "pass_type", val: this.arr.pass_type.join(",") || "" },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑数据前输入数据
    editor2(row) {
      this.row = row;
      this.title2 = `编辑通道(ID：${row.id})`;
      this.arr.title = row.title;
      this.arr.pass_remarks = row.remarks;
      this.arr.pass_code = row.pass_code;
      this.arr.shop_id = row.shop_id
      this.arr.pay_type = row.pay_type_int;
      if (row.pass_type !== "") {
        this.arr.pass_type = row.pass_type.split(",");
        this.arr.pass_type.forEach((v, i) => {
          this.arr.pass_type[i] = Number(v);
        });
      } else {
        this.arr.pass_type = [];
      }
      this.changeShopId(row.shop_id)
      this.dialogAdd = true;
    },
    // 编辑通道
    edit() {
      edit([
        { key: "id", val: this.row.id },
        { key: "title", val: this.arr.title },
        { key: "pass_remarks", val: this.arr.pass_remarks },
        { key: "pass_code", val: this.arr.pass_code },
        { key: "pay_type", val: this.arr.pay_type },
        { key: "shop_id", val: this.arr.shop_id },
        { key: "pass_type", val: this.arr.pass_type.join(",") || "" },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message({ message: res.data, type: "success" });
            this.dialogAdd = false;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除通道：" + row.title + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key: "id", val: row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.msg, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea,
.form_box >>> .el-checkbox-group {
  display: inline-block;
  width: 60%;
}
.form_box >>> .el-checkbox-group .el-checkbox-button__inner {
  border-radius: 0;
  border: 1px solid #ddd;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>